.founder-message {
    padding: 50px 0;
    background-color: #f8f9fa;
  }
  .founder-message h2 {
    color: #333;
  }
  .founder-message p {
    color: #666;
  }
  .company-policy {
    padding: 50px 0;
    background-color: #f8f9fa;
  }
  .policy-item {
    margin-bottom: 20px;
  }
  .policy-item h3 {
    color: #333;
  }
  .policy-item p {
    color: #666;
  }
  .ticket-card {
    margin-bottom: 20px;
  }
  .desk-card {
    margin-bottom: 20px;
  }
 . bg-light-gray{
  background-color:#e9e494;
  color:white
 }
  